



























































































import Vue from 'vue';
import { ApiResource } from '@/types';
import ApplicationFeePdfDownloadButton from '@/components/dashboard/ApplicationFeePdfDownloadButton.vue';

export default Vue.extend({
  components: {
    ApplicationFeePdfDownloadButton,
  },
  props: {
    payment: {
      type: Object as () => ApiResource.Business.Payment,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    CURRENCY_DIVISOR(): number {
      let div = 100;

      switch (this.payment.currency) {
        case 'EUR':
          div = 100;
          break;

        default:
          div = 100;
          break;
      }

      return div;
    },
    earnings(): number {
      return this.payment.amount - this.payment.application_fee_amount;
    },

    fee(): ApiResource.Business.Fee | undefined {
      if (!this.payment.application_fee) return undefined;
      return this.payment.application_fee.data;
    },
    feeRefunds(): ApiResource.Business.FeeRefund[] {
      if (!this.fee) return [];
      if (!this.fee.refunds) return [];
      return this.fee.refunds.data;
    },

    voucher(): ApiResource.Business.InvoiceVoucher | undefined {
      if (!this.fee) return undefined;
      if (!this.fee.voucher) return undefined;

      return this.fee.voucher.data;
    },

    transfer(): ApiResource.Business.Transfer | undefined {
      if (!this.payment.source_transfer) return undefined;
      return this.payment.source_transfer.data;
    },
    transferReversals(): ApiResource.Business.TransferReversal[] {
      if (!this.transfer) return [];
      if (!this.transfer.reversals) return [];
      return this.transfer.reversals.data;
    },
  },
});
