









import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    feeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pdf: null,
      loading: false,
    };
  },
  methods: {
    async download() {
      this.loading = true;

      const blobUrl = await this.$store.dispatch('business/Payments/DownloadFeeInvoice', {
        BUSINESS_ID: this.businessId,
        FEE_ID: this.feeId,
      }).catch((e) => { this.$emit('download-error', e); });

      this.loading = false;

      if (blobUrl) window.open(blobUrl);
    },
  },
});
